@import "./scss/index.scss";
.classLogin {
  background: url("../public/images/bk-genio-documental.jpg");
  background-size: 100%;
  min-height: 100vh;
  position: relative;
}
.genio-logo {
  max-width: 100%;
  height: auto;
}

.btn-login {
  background-color: $background-menu-icon-color !important;
}
.btn-yellow {
  background-color: $background-button-color !important;
  --bs-btn-border-color: none !important;
}
.login-page {
  position: absolute;
  height: 100%;
  width: 100%;
  mix-blend-mode: normal;
}
.forms {
  background-color: rgb(214, 207, 207);
}

.navPrinc {
  background-color: $background-menu-icon-color !important;
  margin-top: 80px;
  height: 65px;
}
.navFilters {
  width: 400px !important;
}
.dropdown-menu {
  --bs-dropdown-padding-y: 0rem !important;
}
.color-cont {
  background-color: rgb(247, 241, 240) !important;
}
.accordion {
  --bs-accordion-active-bg: #ececea !important;
  --bs-accordion-bg: rgb(247, 241, 240) !important;
  --bs-accordion-active-color: $background-menu-icon-color !important;
}
.imageNav {
  position:relative;
  top:-30px;
}

#navs-home{
  border: 0;
  padding: 0;
  z-index: 500;
}

#div-components {
  padding-top: 50px;
}
.navbar-collapse {
  flex-basis: 100% !important;
  flex-grow: 1 !important;
  align-items: center !important;
}
.btn-dark {
  --bs-btn-bg: #000000 !important;
}
.pagination {
  --bs-pagination-color: #252628 !important;
  --bs-pagination-hover-color: none !important;
  --bs-pagination-border-color: none !important;
  --bs-pagination-focus-box-shadow: none !important;
}
.page-link.active, .active > .page-link {
  background-color: #000 !important;
  border-color: #000 !important;
}
.text-doc {
  color: #7F7F7F;
  font-size: 13px;
  padding: 0 8px 0 12px;
}
.dropdown-item {
  --bs-dropdown-link-active-bg: #fff;
}

input[readonly] {
  background-color: $color-disabled-input;
}

.custom-buttons {
  color: $background-button-color !important;
  font-size: 23px;
  cursor: pointer;
}