/*colores de letra*/
$color-button-letter: #ffffff;
$color-link-letter:#ffffff;
$color-menu-letter:#266aa2;
$color-menu-letter-selected: #ffffff;

/*colores de fondo*/
$background-input-color:#ffffff;
$background-button-color: #f6ab00;
$background-navbar-color: #075595;
$background-navbar-color-user: #0678d6;
$background-navbar-color-gear:#f15c41;
$background-navbar-button-user-color: #e0f1fe;

/*Colores del menu*/
$menu-letter-color: #000;
$background-menu-color: #ffffff;
$background-menu-color-selected:  #ffffff;
$background-menu-icon-color: #000;
$background-menu-option:  #ffffff;
$background-menu-option-selected:  #f15c41;
$background-menu-option-hover: #bacfe1;

/*Colores para las tablas*/
$background-table-color: #bacfe1;
$background-options-color: #e9ecef;

/*Colores botones*/
$color-button-plus: #44b05c;
$color-button-delx: #d76152;

/*Colores bordes invalidos*/
$color-border-invalid: #dc3545;
$color-border-valid: #ced4da;

/*Colores Errores*/
$color-message-error:#A90000;
$color-message-confirm:#00500B;
$color-message-warning:#172a7e;
$color-body-error:#fae1e3;
$color-body-confirm:#d4edda;
$color-body-warning:#a1d5f3;

/*Colores input*/
$color-disabled-input:#d9d9d9;

:export {
  backgroundMenuColor: $background-menu-color;
  backgroundMenuColorSelected: $background-menu-color-selected;
  backgroundMenuOption:$background-menu-option;
  backgroundMenuOptionSelected:$background-menu-option-selected;
  colorMenuLetter: $color-menu-letter;
  colorMenuLetterSelected:$color-menu-letter-selected;
  backgroundButtoncolor: $background-button-color;
  backgroundOptionsColor:$background-options-color;
  backgroundInputcolor:$background-input-color;
  colorBorderInvalid:$color-border-invalid;
  colorBorderValid:$color-border-valid;
  backgroundMenuOptionHover:$background-menu-option-hover;
  colorMessageError:$color-message-error;
  colorMessageConfirm:$color-message-confirm;
  colorMessageWarning:$color-message-warning;
  colorBodyError:$color-body-error;
  colorBodyConfirm:$color-body-confirm;
  colorBodyWarning:$color-body-warning;
}



